import { webUrlRegExp, interestedCategoryNamesOptions } from '@/utils/constants'
import { countriesOptions } from '@/utils/countries'

export default {
  name: 'companyOBInfoForm',
  formTitle: '',
  navTitle: '',
  completed: null,
  maxGroupColumns: 12,
  groups: [
    {
      title: '',
      fields: [
        [{
          choice: 'single',
          field: 'input',
          type: 'text',
          name: 'companyName',
          label: 'What is your company name?',
          placeholder: '',
          rules: 'required',
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'input',
          type: 'radio',
          name: 'officerId',
          veeAs: ' ',
          label: 'Are you one of these officers?',
          placeholder: '',
          rules: 'required',
          columns: 2,
          options: [],
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'input',
          type: 'text',
          name: 'otherFirstName',
          veeAs: ' ',
          label: 'Officer First Name',
          placeholder: '',
          rules: 'required',
          selected: '',
          dependsOn: [
            {
              fieldName: 'officerId',
              values: ['Other']
            }
          ]
        }, {
          choice: 'single',
          field: 'input',
          type: 'text',
          name: 'otherLastName',
          veeAs: ' ',
          label: 'Officer Last Name',
          placeholder: '',
          rules: 'required',
          selected: '',
          dependsOn: [
            {
              fieldName: 'officerId',
              values: ['Other']
            }
          ]
        }],
        [{
          choice: 'single',
          field: 'input',
          type: 'radio',
          isInline: false,
          name: 'shareholdingStatus',
          veeAs: ' ',
          label: 'Shareholding status',
          placeholder: '',
          rules: 'required',
          options: [
            { label: 'Shareholder', value: 'Shareholder' },
            { label: 'Not a shareholder', value: 'Not a shareholder' }
          ],
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'input',
          type: 'radio',
          isInline: true,
          name: 'hasYourBusinessStartedTrading',
          veeAs: ' ',
          label: 'Has your business started trading?',
          placeholder: '',
          rules: 'required',
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ],
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'datepicker',
          type: '',
          name: 'trandingStartedDate',
          veeAs: ' ',
          label: 'When did you Start trading?',
          placeholder: '',
          icon: {
            name: 'today',
            position: 'right'
          },
          rules: 'required',
          dependsOn: [
            {
              fieldName: 'hasYourBusinessStartedTrading',
              values: [true]
            }
          ],
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'input',
          type: 'url',
          name: 'website',
          veeAs: 'website',
          label: 'Please enter your business website if you have one.',
          placeholder: '',
          rules: {
            regex: webUrlRegExp
          },
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'input',
          type: 'text',
          name: 'town',
          label: 'What is your Town/City?',
          placeholder: '',
          rules: 'required',
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'input',
          type: 'text',
          name: 'addressLine',
          label: 'What is your registered address?',
          placeholder: '',
          rules: 'required',
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'input',
          type: 'text',
          name: 'postcode',
          label: 'What is your postcode?',
          placeholder: '',
          rules: 'required',
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'input',
          type: 'radio',
          isInline: false,
          name: 'typeOfPremisesOfTradingAddress',
          veeAs: 'type of premises',
          label: 'What type of premises is your trading address?',
          placeholder: '',
          rules: 'required',
          options: [
            {
              label: 'Shop',
              value: 'Shop'
            },
            {
              label: 'Warehouse',
              value: 'Warehouse'
            },
            {
              label: 'Factory',
              value: 'Factory'
            },
            {
              label: 'Office',
              value: 'Office'
            },
            {
              label: 'Home',
              value: 'Home'
            },
            {
              label: 'Other',
              value: 'Other'
            }
          ],
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'input',
          type: 'number',
          name: 'numberOfPremises',
          veeAs: ' ',
          label: 'How many premises, offices, outlets do you have it total?',
          placeholder: '',
          rules: 'required|numeric',
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'input',
          type: 'radio',
          isInline: true,
          name: 'doesBusinessPremisesAbroad',
          veeAs: ' ',
          label: 'Does your business have premises abroad?',
          placeholder: '',
          rules: 'required',
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ],
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'select',
          type: '',
          name: 'countryOfIncorporation',
          veeAs: 'country',
          label: 'What is your country of incorporation/establishment?',
          placeholder: '',
          rules: 'required',
          options: countriesOptions,
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'input',
          type: 'radio',
          isInline: true,
          name: 'isRegisteredForTaxOutsideUK',
          veeAs: ' ',
          label: 'Are you registered for tax outside the UK?',
          placeholder: '',
          rules: 'required',
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ],
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'input',
          type: 'text',
          name: 'taxOutsideUK',
          veeAs: ' ',
          label: 'What is the country and what is the tax identification number?',
          placeholder: '',
          rules: 'required',
          dependsOn: [
            {
              fieldName: 'isRegisteredForTaxOutsideUK',
              values: [true]
            }
          ],
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'input',
          type: 'radio',
          isInline: true,
          name: 'doYouHaveParentCompany',
          veeAs: ' ',
          label: 'Do you have a parent company, headquarters or beneficial owner?',
          placeholder: '',
          rules: 'required',
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ],
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'select',
          type: '',
          name: 'countryOfParentCompany',
          veeAs: 'country',
          label: 'State which country the parent company, headquarters or beneficial owner is based?',
          placeholder: '',
          rules: 'required',
          dependsOn: [
            {
              fieldName: 'doYouHaveParentCompany',
              values: [true]
            }
          ],
          options: countriesOptions,
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'textarea',
          type: 'text',
          name: 'servicesYourBusinessOffers',
          veeAs: ' ',
          label: 'Please tell us a bit about the goods and services your business offers, your customers and the industry where you operate.',
          placeholder: '',
          rules: 'required',
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'input',
          type: 'radio',
          isInline: true,
          name: 'isYourBusinessAuthorisedByPPA',
          veeAs: ' ',
          label: 'Is your business authorised by the Prudential Regulation Authority and/or regulated by the Financial Conduct Authority?',
          placeholder: '',
          rules: 'required',
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ],
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'input',
          type: 'radio',
          isInline: true,
          name: 'hasBusinessSuppliersOutsideUK',
          veeAs: ' ',
          label: 'Will your business or organisations have any suppliers outside the UK?',
          placeholder: '',
          rules: 'required',
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ],
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'select',
          type: '',
          name: 'mainSuppliersBased',
          veeAs: ' ',
          label: 'Where are your main suppliers based?',
          placeholder: '',
          rules: 'required',
          dependsOn: [
            {
              fieldName: 'hasBusinessSuppliersOutsideUK',
              values: [true]
            }
          ],
          options: countriesOptions,
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'input',
          type: 'radio',
          isInline: true,
          name: 'hasYourBusinessClientsOfIncomeOutsideOfTheUK',
          veeAs: ' ',
          label: 'Will your business or organisation have any customers, clients, members or other sources of income outside of the UK?',
          placeholder: '',
          rules: 'required',
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ],
          selected: ''
        }],
        [{
          choice: 'multiple',
          field: 'select',
          type: '',
          name: 'mainCustomersCountries',
          veeAs: ' ',
          label: 'Which countries are your main customers in? (multi-select)',
          placeholder: '',
          rules: 'required',
          dependsOn: [
            {
              fieldName: 'hasYourBusinessClientsOfIncomeOutsideOfTheUK',
              values: [true]
            }
          ],
          options: countriesOptions,
          selected: []
        }],
        [{
          choice: 'single',
          field: 'input',
          type: 'radio',
          isInline: true,
          name: 'doesYourBusinessOfAnyIndustriesList',
          veeAs: ' ',
          label: 'Does your business, your suppliers or your customers offer services of any of the industries below - Currency exchange or money transfer, Defence or weapons, Oil, gas or mineral extraction, Gambling or betting, Consumer credit or money lending?',
          placeholder: '',
          rules: 'required',
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ],
          selected: ''
        }],
        [{
          choice: 'single',
          field: 'input',
          type: 'radio',
          name: 'importantBankProperties',
          veeAs: 'cost savings',
          label: 'When considering a bank account, which of the following is most important to you?',
          placeholder: '',
          rules: 'required',
          columns: 1,
          options: [
            { label: 'Overdraft Facility', value: 'Overdraft Facility' },
            { label: 'Account Fees', value: 'Account Fees' },
            { label: 'FX Fees', value: 'FX Fees' },
            { label: 'International Payments', value: 'International Payments' }
          ],
          selected: ''
        }],
        [{
          choice: 'multiple',
          field: 'input',
          type: 'checkbox',
          name: 'interestedCategoryNames',
          veeAs: 'cost savings',
          label: 'Which of the following does your business spend money on?',
          placeholder: '',
          rules: 'required',
          columns: 1,
          options: interestedCategoryNamesOptions,
          selected: []
        }]
      ]
    }
  ]
}
