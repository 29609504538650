<template src="./openBanking.html"></template>
<style src="./openBanking.scss" lang="scss" scoped></style>

<script>
import deepcopy from 'deepcopy'
import { mapGetters, mapActions } from 'vuex'
import { companyGetters, companyStateRoutine } from '@/store/modules/company/routines'
import { fundingGetters } from '@/store/routines'
import { userRoutine } from '@/store/modules/auth/routines'
import DynamicForm from '@/components/forms/DynamicForm'
import { getCompany, getCompanyOfficers, patchCompanyOpenBanking } from '@/api/company'
import companyOBInfoForm from './openBanking.form'
import { prefillFormData } from '@/utils/formHelpers'
import { getOfficerName } from '@/utils/utils'

export default {
  name: 'JourneyOpenBanking',
  components: {
    DynamicForm
  },
  data() {
    return {
      isLoading: true,
      companyOBInfoForm: deepcopy(companyOBInfoForm),
      submitPromise: null
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      fundingApplicationId: fundingGetters.FUNDING_APP_ID
    })
  },
  methods: {
    ...mapActions({
      requestCompanyState: companyStateRoutine.TRIGGER,
      requestUser: userRoutine.TRIGGER
    }),
    prefillCompanyOBInfoFormData() {
      return Promise.all([getCompany(this.companyId), getCompanyOfficers(this.companyId)])
        .then(([resCompany, resOfficers]) => {
          const officerOptions = resOfficers.data.map(officer => ({ value: officer.officerId, label: getOfficerName(officer) }))
          officerOptions.push({ value: 'Other', label: 'Other' })

          const formOptions = {
            officerId: officerOptions
          }
          prefillFormData(this.companyOBInfoForm, resCompany.data, formOptions)
        })
        .catch(e => {
          console.log(e)
        })
    },
    submitCompanyOBInfo(formData) {
      const { companyId } = this
      const officerId = formData.data.officerId === 'Other' ? null : formData.data.officerId

      const companyData = {
        ...formData.data,
        officerId,
        companyId
      }

      this.submitPromise = patchCompanyOpenBanking(companyData)
        .then(() => this.requestCompanyState(companyId))
        .then(this.requestUser)
        .then(() => {
          this.$router.push({ name: 'dashboard' })
        })
      return this.submitPromise
    }
  },
  async created() {
    await this.prefillCompanyOBInfoFormData()
    this.isLoading = false
  }
}
</script>
